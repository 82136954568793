import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 264.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,264.000000) scale(0.100000,-0.100000)">

<path d="M915 2419 c-355 -68 -618 -344 -665 -701 -8 -57 -10 -211 -8 -455 5
-391 7 -408 60 -543 41 -102 90 -174 182 -265 133 -131 264 -201 434 -230 117
-21 1049 -21 1162 -1 350 63 620 338 670 682 13 93 13 733 -1 830 -22 163
-109 336 -228 455 -99 99 -220 168 -386 220 -50 16 -1142 23 -1220 8z m1240
-53 c278 -85 478 -291 545 -561 18 -73 20 -115 20 -486 0 -443 -3 -476 -63
-611 -98 -220 -321 -399 -554 -444 -94 -18 -1109 -21 -1195 -3 -142 29 -274
97 -380 197 -123 116 -192 234 -229 389 -19 79 -20 113 -17 506 l3 422 28 80
c102 290 340 490 637 534 37 5 295 8 590 7 l525 -2 90 -28z"/>
<path d="M780 2217 c-30 -16 -51 -37 -67 -67 l-23 -43 0 -566 c0 -530 2 -569
19 -607 22 -48 59 -77 118 -93 29 -8 233 -10 680 -9 l638 3 45 25 c32 18 52
38 68 70 l22 44 0 570 0 569 -26 45 c-18 30 -39 50 -67 63 -40 18 -77 19 -703
19 l-661 0 -43 -23z m1408 -17 c18 -11 41 -34 52 -52 19 -32 20 -52 20 -609
l0 -576 -23 -33 c-49 -74 -7 -70 -751 -70 -746 -1 -704 -5 -753 70 l-23 33 0
577 0 577 23 34 c48 72 16 69 757 69 646 0 666 -1 698 -20z"/>
<path d="M1911 2161 c-6 -4 8 -16 34 -29 100 -49 172 -142 182 -237 8 -75 29
-67 25 9 -3 74 -38 143 -94 189 -50 40 -131 78 -147 68z"/>
<path d="M1904 2076 c26 -14 64 -39 83 -58 41 -38 83 -120 83 -160 0 -16 5
-28 10 -28 15 0 12 61 -6 113 -25 74 -129 158 -196 157 -17 -1 -10 -7 26 -24z"/>
<path d="M1840 2035 c0 -3 17 -12 38 -20 68 -29 131 -111 132 -172 0 -13 5
-23 11 -23 18 0 3 87 -22 127 -22 35 -110 93 -141 93 -10 0 -18 -2 -18 -5z"/>
<path d="M1836 1931 c-24 -26 -17 -62 17 -81 24 -13 30 -13 55 0 51 29 32 100
-28 100 -15 0 -35 -9 -44 -19z"/>
<path d="M1008 1933 c-49 -2 -88 -8 -88 -13 0 -6 13 -10 29 -10 17 0 41 -10
55 -22 21 -19 27 -37 35 -103 12 -103 12 -385 1 -494 -10 -92 -21 -110 -66
-115 -16 -2 -33 -4 -37 -5 -5 0 -6 -4 -4 -9 3 -4 67 -6 142 -5 75 1 142 0 150
-3 7 -3 16 -20 19 -38 13 -67 100 -127 203 -141 188 -25 403 135 403 299 0 83
-80 165 -170 174 -28 2 -50 7 -50 11 1 3 22 19 47 36 107 68 143 115 143 189
0 36 -6 52 -30 80 -35 39 -102 66 -167 66 l-43 0 0 55 0 55 -242 -2 c-134 -1
-282 -4 -330 -5z m478 -41 c23 -11 48 -32 57 -45 15 -24 15 -24 -38 -41 -65
-20 -136 -81 -165 -141 -24 -50 -21 -87 5 -65 19 16 21 14 28 -31 l5 -37 -29
11 c-21 8 -29 18 -29 35 0 19 -4 23 -17 17 -10 -4 -48 -10 -85 -12 l-68 -6 0
150 c0 104 4 154 13 165 16 21 48 25 177 23 85 -1 113 -6 146 -23z m-381 0 c9
-6 16 -182 12 -307 0 -16 -1 -110 -1 -208 l-1 -177 -24 0 c-22 0 -23 2 -17 43
11 62 7 529 -4 588 -6 28 -7 55 -4 60 6 11 25 11 39 1z m570 -122 c57 -54 52
-155 -11 -229 -19 -22 -73 -70 -119 -107 -47 -37 -85 -71 -85 -76 0 -17 23 -7
64 27 62 51 96 60 140 40 49 -23 70 -72 62 -143 -8 -66 -62 -186 -102 -225
-59 -59 -152 -74 -224 -37 -47 24 -58 55 -40 111 12 35 11 41 -5 59 -23 25
-55 26 -86 1 -31 -24 -73 -17 -99 17 -18 22 -20 40 -20 189 l0 165 75 -4 c70
-3 77 -5 113 -41 34 -33 43 -37 92 -37 50 0 56 3 97 45 37 39 55 75 38 75 -3
0 -23 -18 -44 -40 -41 -43 -64 -49 -99 -24 -18 12 -22 25 -22 65 0 78 42 144
115 184 44 23 128 16 160 -15z m89 -26 c33 -34 35 -85 4 -131 -29 -42 -39 -42
-30 0 9 43 7 85 -6 125 -13 39 -3 41 32 6z m25 -393 c31 -43 35 -82 16 -140
-9 -28 -20 -51 -23 -51 -4 0 -12 -10 -17 -22 -11 -23 -62 -65 -70 -57 -2 3 10
39 27 80 26 60 32 88 33 146 0 40 3 73 7 73 4 0 16 -13 27 -29z m-464 -218
c-4 -21 -8 -44 -8 -50 -1 -22 -13 -14 -32 21 -22 41 -15 66 21 66 25 0 26 -2
19 -37z"/>
<path d="M595 710 c3 -5 15 -10 25 -10 15 0 20 -9 23 -42 l4 -43 1 46 c2 41
-1 47 -20 53 -33 8 -40 7 -33 -4z"/>
<path d="M865 695 c-14 -13 -25 -30 -25 -37 1 -7 10 -2 21 12 13 15 29 24 41
22 11 -2 18 1 14 6 -4 6 2 8 15 5 26 -7 49 -42 44 -68 -2 -11 -4 -22 -4 -25
-1 -3 -6 2 -12 10 -9 13 -10 13 -6 -1 4 -11 -4 -21 -19 -30 -15 -8 -20 -15
-13 -19 14 -9 64 38 74 68 5 17 -1 29 -24 52 -36 37 -73 39 -106 5z"/>
<path d="M1174 713 c4 -5 12 -9 17 -9 5 -1 14 -2 19 -3 6 -1 10 -50 10 -127 0
-117 -1 -126 -20 -131 -11 -3 -20 -8 -20 -12 0 -11 214 4 238 17 36 20 62 72
62 122 0 96 -53 140 -166 140 l-73 0 -4 -137 -3 -138 -2 136 c-2 132 -3 137
-24 143 -31 8 -43 7 -34 -1z m228 -51 c46 -57 48 -123 5 -179 l-21 -28 22 19
c50 43 52 131 4 187 -24 29 -24 31 -4 20 68 -36 69 -185 1 -222 -18 -9 -74
-13 -136 -10 -10 1 -13 32 -13 119 0 65 3 122 7 126 4 4 30 6 58 4 43 -3 54
-8 77 -36z"/>
<path d="M1643 683 c-8 -21 -32 -81 -53 -133 -25 -64 -45 -98 -59 -105 -32
-15 -25 -20 29 -20 28 0 50 3 50 7 0 5 -9 8 -20 8 -23 0 -25 17 -8 55 10 23
16 25 74 25 56 0 63 2 57 17 -4 11 -8 12 -11 5 -3 -11 -102 -18 -102 -8 0 2
10 29 21 60 20 51 39 73 39 44 0 -6 3 -9 6 -6 3 4 1 15 -5 27 -6 11 -7 22 -3
25 5 3 17 -18 27 -47 10 -29 33 -85 51 -124 32 -72 35 -90 8 -55 -13 16 -14
16 -8 0 5 -13 1 -18 -14 -18 -12 0 -23 -3 -25 -7 -3 -5 24 -8 59 -8 62 0 83 8
46 17 -13 4 -37 48 -77 142 -31 75 -59 136 -62 136 -2 0 -11 -17 -20 -37z"/>
<path d="M1831 689 c0 -31 1 -32 9 -11 8 18 17 22 54 22 34 0 45 -4 48 -17 3
-15 5 -14 10 5 6 21 3 22 -42 22 -27 0 -55 3 -64 6 -13 5 -16 -1 -15 -27z"/>
<path d="M2023 713 l-53 -4 0 -135 c0 -76 -4 -134 -9 -134 -5 0 -12 10 -14 23
l-4 22 -2 -22 c-1 -16 -7 -23 -21 -23 -11 0 -20 -3 -20 -7 0 -5 31 -8 69 -8
38 0 67 3 64 8 -2 4 -13 7 -24 7 -18 0 -19 8 -19 130 l0 130 49 0 c45 0 49 -2
56 -27 4 -16 9 -23 12 -17 5 14 -14 65 -24 62 -5 -1 -32 -3 -60 -5z"/>
<path d="M2209 584 c-41 -103 -59 -138 -76 -145 -13 -5 -23 -10 -23 -11 0 -2
25 -3 55 -3 30 0 55 3 55 8 0 4 -12 7 -26 7 -20 0 -25 4 -20 16 3 9 9 27 12
40 6 22 11 24 70 24 35 0 64 3 64 8 0 4 -27 5 -60 4 -57 -4 -70 2 -52 24 5 5
17 31 27 58 13 35 20 44 27 35 5 -9 6 -3 2 16 -10 46 2 27 37 -56 60 -144 69
-169 60 -169 -5 0 -12 8 -14 18 -4 16 -5 16 -6 0 -1 -11 -9 -18 -20 -18 -11 0
-23 -5 -27 -12 -5 -8 -2 -9 10 -5 10 4 41 8 69 8 29 1 45 3 35 6 -15 4 -40 54
-117 236 -11 26 -22 47 -24 47 -2 0 -28 -61 -58 -136z"/>
<path d="M658 573 c-2 -129 -2 -133 -8 -53 l-6 85 -2 -82 c-2 -76 -4 -82 -24
-86 -13 -2 0 -5 29 -6 28 0 58 -4 67 -7 9 -3 16 -1 16 4 0 6 -10 12 -22 14
-21 3 -23 9 -26 66 l-3 62 45 0 c34 0 46 -4 49 -17 3 -10 5 2 5 27 1 36 -1 41
-8 23 -8 -19 -17 -23 -50 -23 l-41 0 3 58 3 57 46 3 c39 3 47 0 57 -20 11 -23
11 -23 11 5 1 27 1 27 -69 27 l-70 0 -2 -137z"/>
<path d="M953 556 c0 -8 5 -14 11 -13 16 3 20 -61 5 -79 -7 -8 -18 -13 -26
-10 -8 3 -11 1 -8 -4 12 -20 -43 -9 -79 15 -41 28 -49 17 -10 -14 59 -46 154
-13 154 54 0 18 -7 41 -16 49 -19 20 -31 20 -31 2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
